import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Box,
  Button,
  Group,
  Input,
  Loader,
  MultiSelect,
  Progress,
  Select,
  Stack,
  Text,
  Textarea,
} from "@mantine/core";
import { Dropzone, FileWithPath } from "@mantine/dropzone";
import {
  PostUserDetailsValues,
  UserRole,
  postUserDetailsSchema,
} from "@voluntasker/common";
import { collection, doc } from "firebase/firestore";
import { ref as storageRef } from "firebase/storage";
import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { useUploadFile } from "react-firebase-hooks/storage";
import { Controller, useForm } from "react-hook-form";
import { FaCloudUploadAlt } from "react-icons/fa";
import { ElementsProvider } from "src/components/ElementsProvider";
import { StorageImage } from "src/components/StorageImage";
import { UserIdentityVerifyButton } from "src/components/user/UserIdentityVerifyButton";
import { useUserDetails } from "src/hooks/useUserDetails";
import { auth, firestore, storage } from "src/lib/firebase";
import { readBlobAsync } from "src/lib/toolt";
import { FormComponentProps } from "src/lib/types";
import { LocationAutocomplete } from "src/components/LocationAutocomplete";
import { UserEmailVerifyButton } from "src/components/user/UserEmailVerifyButton";

interface UserDetailsFormProps
  extends FormComponentProps<PostUserDetailsValues> {
  isModal?: boolean;
}

export const UserDetailsForm: React.FC<UserDetailsFormProps> = ({
  onSubmit,
  error,
  submitting,
  isModal,
}) => {
  const [user] = useAuthState(auth);
  const [userDetailsDoc, userDetailsLoading] = useUserDetails();

  const isVolunteer = useMemo(
    () => userDetailsDoc?.data()?.roles?.includes(UserRole.Volunteer),
    [userDetailsDoc]
  );

  const userDetails = useMemo(() => userDetailsDoc?.data(), [userDetailsDoc]);
  const [uploadFile, uploading, snapshot] = useUploadFile();
  // const sigRef = useRef<SignatureCanvas>(null);

  const form = useForm<PostUserDetailsValues>({
    resolver: yupResolver(postUserDetailsSchema),
    defaultValues: {
      firstName: userDetails?.firstName,
      lastName: userDetails?.lastName,
      mobile: userDetails?.mobile,
      wwcNumber: userDetails?.wwcNumber,
    },
  });

  useEffect(() => {
    if (userDetails?.firstName) {
      form.setValue("firstName", userDetails?.firstName);
    }
    if (userDetails?.lastName) {
      form.setValue("lastName", userDetails?.lastName);
    }
    if (userDetails?.mobile) {
      form.setValue("mobile", userDetails?.mobile);
    }
    if (userDetails?.wwcNumber) {
      form.setValue("wwcNumber", userDetails?.wwcNumber);
    }
    if (userDetails?.skills) {
      form.setValue("skills", userDetails?.skills);
    }
    if (userDetails?.about) {
      form.setValue("about", userDetails?.about);
    }
  }, [userDetailsDoc]);

  const [latestImage, setLatestImage] = useState<string>();

  const _onSubmit = form.handleSubmit(onSubmit);

  const onDrop = useCallback(
    async (files: FileWithPath[]) => {
      const file = files[0];
      if (!user?.uid || !file) {
        return;
      }
      const imageId = doc(collection(firestore, "uuid")).id;
      const fileRef = storageRef(storage, `/profiles/${user.uid}/${imageId}`);
      const result = await uploadFile(fileRef, file, {
        contentType: file.type,
      });
      if (result?.ref.fullPath) {
        const blobData = await readBlobAsync(file);
        setLatestImage(blobData);
        form.setValue("imageRef", result.ref.fullPath);
      }
    },
    [user]
  );

  const progress = useMemo(() => {
    if (snapshot?.bytesTransferred && snapshot.totalBytes) {
      return (snapshot?.bytesTransferred / snapshot?.totalBytes) * 100;
    }
    return 0;
  }, [snapshot?.bytesTransferred]);

  if (userDetailsLoading) {
    createPortal;
    return (
      <Stack align="center">
        <Loader />
      </Stack>
    );
  }

  return (
    <form onSubmit={_onSubmit}>
      <Stack>
        {/* {!isModal && userDetails?.roles && (
          <Group>
            {userDetails?.roles.map((role) => (
              <Badge key={role}>{role}</Badge>
            ))}
          </Group>
        )} */}
        {latestImage ? (
          <img
            height={128}
            width={128}
            src={latestImage}
            style={{ borderRadius: "100%" }}
          />
        ) : (
          userDetails?.imageRef && (
            <StorageImage
              height={128}
              width={128}
              storageRef={userDetails?.imageRef}
              style={{ borderRadius: "100%" }}
            />
          )
        )}
        {!isModal && (
          <Input.Wrapper>
            <Stack>
              <Dropzone
                multiple={false}
                onDrop={onDrop}
                accept={{
                  "image/*": [], // All images
                }}
              >
                <Stack align="center" spacing={0}>
                  <Text size="md" fw="bold">
                    Drag or Tap
                  </Text>
                  <Text color="dimmed">To change profile picture</Text>
                  <FaCloudUploadAlt size={32} />
                </Stack>
              </Dropzone>
              {uploading && (
                <Progress animate color="indigo" value={progress} />
              )}
            </Stack>
          </Input.Wrapper>
        )}
        <Input.Wrapper label="Email Address">
          <Input value={user?.email || ""} disabled />
        </Input.Wrapper>
        <Group>
          <Input.Wrapper label="First Name" withAsterisk>
            <Input
              disabled={submitting}
              placeholder="Enter First Name"
              {...form.register("firstName")}
            />
          </Input.Wrapper>
          <Input.Wrapper label="Last Name" withAsterisk>
            <Input
              disabled={submitting}
              placeholder="Enter First Name"
              {...form.register("lastName")}
            />
          </Input.Wrapper>
        </Group>

        <Input.Wrapper label="About you" withAsterisk>
          <Textarea
            disabled={submitting}
            placeholder="Enter a bit about yourself"
            {...form.register("about")}
          />
        </Input.Wrapper>

        <Input.Wrapper label="Address" withAsterisk>
          <LocationAutocomplete
            value={userDetails?.address}
            disabled={submitting}
            onChange={(places) => {
              if (places.formatted_address) {
                form.setValue("address", places.formatted_address);
              }
              const region = places.address_components?.find((component) =>
                component.types.includes("administrative_area_level_2")
              )?.short_name;
              if (region) {
                form.setValue("region", region);
              }
            }}
          />
        </Input.Wrapper>

        {!isModal && (
          <Input.Wrapper label="Mobile Number">
            <Input
              disabled={submitting}
              placeholder="Enter a mobile number"
              {...form.register("mobile")}
            />
          </Input.Wrapper>
        )}

        {!isModal && (
          <Input.Wrapper label="Add Skills" withAsterisk>
            <Controller
              control={form.control}
              name="skills"
              render={({ field }) => (
                <MultiSelect
                  shouldCreate={(value) => !!value}
                  onCreate={(value) => {
                    form.setValue("skills", [...(field.value || []), value]);
                    return value;
                  }}
                  creatable
                  searchable
                  enterKeyHint="done"
                  placeholder="Enter and add one or more skills"
                  getCreateLabel={(query) => `+ Add Skill ${query}`}
                  disabled={submitting}
                  data={field.value || []}
                  {...field}
                />
              )}
            />
          </Input.Wrapper>
        )}

        {isVolunteer && (
          <Input.Wrapper label="Working With Children Number (if applicable)">
            <Group>
              <Input
                disabled={submitting}
                placeholder="Enter your Working With Children number"
                {...form.register("wwcNumber")}
              />
              <Controller
                control={form.control}
                name="wwcState"
                render={({ field }) => (
                  <Select
                    disabled={submitting}
                    data={["ACT", "NSW", "NT", "QLD", "SA", "TAS", "VIC", "WA"]}
                    placeholder="Select State"
                    {...form.register("wwcState")}
                    onChange={(value) => {
                      value && field.onChange(value);
                    }}
                  />
                )}
              />
            </Group>
          </Input.Wrapper>
        )}

        <Group>
          <ElementsProvider>
            <Box>
              <UserIdentityVerifyButton />
            </Box>
          </ElementsProvider>
          <UserEmailVerifyButton />
        </Group>

        {/* {!userDetails?.signature && isVolunteer && (
          <Stack>
            <Text>
              Please read and confirm acceptance of the{" "}
              <Anchor href="/VolunteerAgreement.pdf" target="_blank">
                Volunteer Agreement
              </Anchor>{" "}
              by digitally signing the Agreement below:
            </Text>
            <Input.Wrapper label="Signature" withAsterisk>
              <Card withBorder shadow="none" p={0}>
                <SignatureCanvas
                  ref={sigRef}
                  penColor="black"
                  onEnd={() => {
                    const signature = sigRef.current?.getCanvas().toDataURL();
                    if (signature) {
                      form.setValue("signature", signature);
                    }
                  }}
                  canvasProps={{
                    width: 570,
                    height: 72,
                    className: "sigCanvas",
                  }}
                />
              </Card>
            </Input.Wrapper>
          </Stack>
        )} */}

        {error && (
          <Alert color="red" variant="filled">
            {String(error)}
          </Alert>
        )}
        <Stack align={isModal ? undefined : "start"}>
          <Button
            type="submit"
            loading={submitting}
            size={isModal ? "lg" : undefined}
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
