import React, { useEffect, useState } from "react";
import {
  Alert,
  Anchor,
  Box,
  Button,
  Checkbox,
  Image,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { EmailPasswordFormValues } from "src/components/auth/EmailPasswordForm";
import { AuthError, createUserWithEmailAndPassword } from "firebase/auth";
import { auth, prepareFirebaseError } from "src/lib/firebase";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { HalfPage } from "src/components/HalfPage";
import LogoPng from "src/assets/img/Logo.png";
import SignInJpg from "src/assets/img/SignIn.jpg";
import { useAuthState } from "react-firebase-hooks/auth";
import { postUserDetails } from "src/lib/api";
import { yupResolver } from "@hookform/resolvers/yup";
import { signUpFormSchema } from "@voluntasker/common";

interface SignUpFormValues extends EmailPasswordFormValues {
  firstName: string;
  lastName: string;
}

export const SignUpPage: React.FC = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const [termsChecked, setTermsChecked] = useState(false);
  const [cocChecked, setCocChecked] = useState(false);
  const [privacyChecked, setPrivacyChecked] = useState(false);

  const { watch, register, handleSubmit } = useForm<SignUpFormValues>({
    resolver: yupResolver(signUpFormSchema),
  });

  const firstName = watch("firstName");
  const lastName = watch("lastName");

  const onSubmit = handleSubmit(async (values) => {
    setLoading(true);
    setError(null);
    try {
      await createUserWithEmailAndPassword(auth, values.email, values.password);
    } catch (e) {
      const error = e as AuthError;
      setError(error);
      setLoading(false);
    }
  });

  useEffect(() => {
    (async () => {
      if (user?.uid) {
        try {
          await postUserDetails({
            firstName,
            lastName,
          });
        } catch (e) {
          const err = e as Error;
          setError(err);
        }
        navigate("/");
      }
    })();
  }, [user, firstName, lastName]);

  return (
    <HalfPage leftSide={<img src={SignInJpg} />}>
      <form onSubmit={onSubmit}>
        <Stack spacing="xl">
          <Image src={LogoPng} width={156} />
          <Stack spacing="xs">
            <Title order={3}>Sign Up</Title>
            <Text color="dimmed">
              Enter your email address and create a password below to sign up to
              Voluntasker. For corporate partner volunteers, please use your
              work email.
            </Text>
          </Stack>
          <Stack spacing="md">
            <Box>
              <Stack spacing="xs">
                <TextInput
                  required
                  label="First Name"
                  type="firstName"
                  {...register("firstName")}
                  disabled={loading}
                />
                <TextInput
                  required
                  label="Last Name"
                  type="lastName"
                  {...register("lastName")}
                  disabled={loading}
                />
                <TextInput
                  label="Email"
                  type="email"
                  {...register("email")}
                  disabled={loading}
                />
                <TextInput
                  label="Password"
                  type="password"
                  {...register("password")}
                  disabled={loading}
                />
              </Stack>
            </Box>
            {error && <Alert color="red">{prepareFirebaseError(error)}</Alert>}

            <Stack spacing="xs">
              <Checkbox
                label={
                  <Text>
                    I have read and agree to the{" "}
                    <Anchor
                      href="/Voluntasker-Terms-and-Conditions-of-Use.pdf"
                      target="_blank"
                    >
                      Terms and Conditions of Use
                    </Anchor>
                  </Text>
                }
                value="terms"
                onChange={({ target: { checked } }) => setTermsChecked(checked)}
              />
              <Checkbox
                label={
                  <Text>
                    I have read and agree to the{" "}
                    <Anchor
                      href="/Voluntasker-Code-of-Conduct.pdf"
                      target="_blank"
                    >
                      Code of Conduct
                    </Anchor>
                  </Text>
                }
                value="terms"
                onChange={({ target: { checked } }) => setCocChecked(checked)}
              />
              <Checkbox
                label={
                  <Text>
                    I have read and agree to the{" "}
                    <Anchor
                      href="/Action-on-Poverty-Voluntasker-Platform-Privacy-Collection-Notice.pdf"
                      target="_blank"
                    >
                      Privacy Collection Notice
                    </Anchor>
                  </Text>
                }
                value="terms"
                onChange={({ target: { checked } }) =>
                  setPrivacyChecked(checked)
                }
              />
            </Stack>

            <Button
              loading={loading}
              type="submit"
              disabled={!termsChecked || !cocChecked || !privacyChecked}
            >
              Create Account
            </Button>
          </Stack>
          <Text>
            Already have an account?{" "}
            <Link to="/signin">
              <Button size="xs" variant="outline">
                Sign In
              </Button>
            </Link>
          </Text>
        </Stack>
      </form>
    </HalfPage>
  );
};
